<template>
  <div>
    <CModal
      :show.sync="$store.state.showModal"
      :no-close-on-backdrop="true"
      :centered="true"
      title="Modal title 2"
      size="lg"
      color="dark"
    >
      <CRow v-if="form">
        <CCol col="12">
          <CCard>
            <CCardBody>
              <CForm>
              <CRow class="mt-3">
                <CCol sm="12" md="12">
                  <CInput invalid description="" label="الاسم" horizontal autocomplete="name" v-model.trim="form.name"
                    invalid-feedback="يوجد خطأ في هذا الحقل" :is-valid="!$v.form.name.$anyError" />
                </CCol>
              </CRow>


              <CRow class="mt-5">
                <CCol sm="12" md="12">
                  <div role="group" class="form-group form-row">
                    <label class="col-form-label col-sm-3">الصلاحيات</label>
                    <CCol sm="9">
                      <v-select :multiple="true" :options="permissions" v-model="form.permissions"
                        :reduce="(permission) => permission.id" dir="rtl" label="name" placeholder="اختار الصلاحيات">
                        <template> عذراً هذه الصلاحيات غير موجود </template>
                        <template v-if="$v.form.permissions.$anyError" #footer>
                          <div style="opacity: 0.8" class="text-danger mt-2">
                            يجب عليك اختيار الصلاحيات
                          </div>
                        </template>
                      </v-select>
                    </CCol>
                  </div>
                </CCol>
              </CRow>
            </CForm>
            </CCardBody>
            <CCardFooter v-if="formErrors">
              <CAlert color="danger">
                <CListGroup flush>
                  <CListGroupItem
                    v-for="(err, index) in formErrors"
                    :key="index"
                  >
                    {{ err[0] }}
                  </CListGroupItem>
                </CListGroup>
              </CAlert>
            </CCardFooter>
          </CCard>
        </CCol>
      </CRow>
      <template v-if="form" #header>
        <h6 class="modal-title font-weight-bold">
          تعديل الصلاحية رقم : {{ form.id }}
        </h6>
        <CButtonClose @click="closeModal" class="text-white" />
      </template>
      <template #footer>
        <CButton @click="closeModal" color="danger">الغاء</CButton>

        <CButton
          :disabled="loading"
          @click="update"
          type="button"
          color="success"
        >
          <CSpinner v-if="loading" color="white" size="sm" />
          <span v-else> <CIcon name="cil-save" /> حفظ </span>
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
export default {
  name: "Edit",
  props: ["form", "permissions"],
  data() {
    return {
      loading: false,
      formErrors: null,
    };
  },
  validations: {
    form: {
      name: { required, min: minLength(2) },
      permissions: { required }
    },
  },
  created() {},
  methods: {
    closeModal() {
      this.$store.commit("set", ["showModal", false]);
    },
    update() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return true;
      }
      this.loading = true;
      const data = (({ name, permissions }) => ({
        name,
        permissions
      }))(this.form);

      this.$http
        .put(`/roles/${this.form.id}`, data)
        .then((res) => {
          // handle success
          if (res.data.status == 200) {
            this.$emit("updateDone");
            this.$store.commit("set", ["showModal", false]);
          }
          this.loading = false;
        })
        .catch((error) => {
          // handle error
          this.loading = false;
          if (error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
    },
  },
};
</script>
